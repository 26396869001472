<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <g clip-path="url(#clip0_1015_193)">
      <path
        d="M10.5 9V9.5C10.5 10.05 10.05 10.5 9.5 10.5H2.5C1.945 10.5 1.5 10.05 1.5 9.5V2.5C1.5 1.95 1.945 1.5 2.5 1.5H9.5C10.05 1.5 10.5 1.95 10.5 2.5V3H6C5.445 3 5 3.45 5 4V8C5 8.55 5.445 9 6 9H10.5ZM6 8H11V4H6V8ZM8 6.75C7.585 6.75 7.25 6.415 7.25 6C7.25 5.585 7.585 5.25 8 5.25C8.415 5.25 8.75 5.585 8.75 6C8.75 6.415 8.415 6.75 8 6.75Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_1015_193">
        <rect width="12" height="12" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "Balance",
};
</script>
