import Vue from 'vue';
import VueRouter from 'vue-router';
import queryString from 'query-string';
import easyScroll from 'easy-scroll';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [

  {
    path: '/:prefix?/redirect',
    name: 'redirect',
    meta: {
      layout: 'clean',
    },
    component: () => import(/* webpackChunkName: "showcase" */ '../views/Redirect.vue'),
  },
  {
    path: '/:prefix?/',
    name: 'home',
    component: Home,
    meta: {
      layout: 'clean',
      title: 'Лучшие предложения',
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      const elem = to.hash.replace(/#/g, '');
      const pixels = document.getElementById(elem)
        .getBoundingClientRect().top - 200;
      return easyScroll({
        scrollableDomEle: window,
        direction: 'bottom',
        duration: 600,
        easingPreset: 'linear',
        scrollAmount: pixels,
      });
    }
    return {
      x: 0,
      y: 0,
    };
  },
});

if (!window.TL) {
  window.TL = {};
}

const parsed = queryString.parse(window.location.search);

if (parsed.utm_source) {
  if (parsed.affiliate_id) parsed.utm_campaign = parsed.affiliate_id;
  if (parsed.wm_chan) parsed.utm_campaign = parsed.wm_chan;
  window.TL.source = parsed;
  window.TL.source_raw = btoa(queryString.stringify(parsed));
}

router.beforeEach((to, from, next) => {
  if (to.meta.title) document.title = to.meta.title;

  let queryChanged = false;

  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of Object.entries(from.query)) {
    if (to.query[key] === undefined) queryChanged = true;
    // eslint-disable-next-line no-param-reassign
    to.query[key] = value;
  }

  if (queryChanged) {
    next({
      path: to.path,
      query: to.query,
    });
  } else {
    next();
  }
});

router.afterEach((to, from) => {
  if (process.env.NODE_ENV === 'production') {
    // eslint-disable-next-line no-undef
    ym(process.env.VUE_APP_YANDEX_METRIKA, 'hit', to.path, {
      title: to.name,
      referer: window.location.origin + from.path,
    });
  }
});

export default router;
