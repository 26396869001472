import Vue from 'vue';
import Vuex from 'vuex';
import common from 'timeleads-frontend-components/src/store/modules/common';
import feedback from 'timeleads-frontend-components/src/store/modules/feedback';
import model from 'timeleads-frontend-components/src/store/modules/model';
import unsubscribe from 'timeleads-frontend-components/src/store/modules/unsubscribe';
import login from 'timeleads-frontend-components/src/store/modules/login';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    common,
    feedback,
    model,
    unsubscribe,
    login,
  },
});
