<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      env: process.env,
    };
  },
};
</script>

<style lang="scss">
@import "./styles/main.scss";
</style>
