import Vue from 'vue';
import {
  setupBus,
  originalSource,
  AdsFin,
  AdsFinId,
  setupCheating,
  setupTrustChecker,
} from 'timeleads-frontend-components/src';
import webs from '@/config/trustedWebs.json';
import App from './App.vue';
import store from './store';
import router from './router';
import 'timeleads-frontend-components/src/validate';
import 'suggestions-jquery';

window.redirectToSuccess = function redirectToSuccess() {
  // GOTO SUCCESS PAGE store.dispatch('RESET_FORM', 'model');
  // window.location.href = `/success${window.location.search}`;
  window.location.href = `/activation/check${window.location.search}`;
};

setupBus();
setupTrustChecker(webs);
originalSource.setup();
setupCheating();
Vue.config.productionTip = false;

Vue.component('ads-fin', AdsFin);
Vue.component('ads-fin-id', AdsFinId);
Vue.prototype.$minPrice = process.env.VUE_APP_MIN_PRICE;
Vue.prototype.$maxPrice = process.env.VUE_APP_MAX_PRICE;
Vue.prototype.$subDays = process.env.VUE_APP_SUB_DAYS;
Vue.prototype.$subMonth = process.env.VUE_APP_SUB_MONTH;
Vue.prototype.$isFree = process.env.VUE_APP_IS_FREE === 'true';

Vue.config.productionTip = false;

// делаю проверку не только на model но и на customer_query,
// чтобы не сломать сайт без customer_query
if (window.location.href.includes('customer_') && !localStorage.getItem('model')) {
  // добавляю дефолтные значения model, на случай если в customer_query их не будет
  const defaultModel = {
    history: {},
    loan_amount: 40000,
    loan_days: '20',
    loan_period: '20 дней',
  };
  localStorage.setItem('model', JSON.stringify(defaultModel));
  // без modelExpires model удаляется, так прописано в либе, поэтому я добавляю его тут
  const modelExpires = new Date();
  modelExpires.setHours(modelExpires.getHours() + +process.env.VUE_APP_TIMER);
  window.localStorage.modelExpires = modelExpires;
}

const urlSearchParams = new URLSearchParams(window.location.search);
const query = Object.fromEntries(urlSearchParams.entries());
const keys = Object.keys(query);

if (keys.some((a) => a.includes('customer_'))) {
  const customerQueries = Object.entries(query).filter((a) => a[0].includes('customer_'));
  const newModel = JSON.parse(localStorage.getItem('model'));
  // eslint-disable-next-line no-restricted-syntax
  for (const [name, value] of customerQueries) {
    newModel[name.replace('customer_', '')] = value;
  }
  localStorage.setItem('model', JSON.stringify(newModel));

  const nonCustomerQueries = {};

  // eslint-disable-next-line no-restricted-syntax
  for (const key in query) {
    if (!key.includes('customer_')) {
      nonCustomerQueries[key] = query[key];
    }
  }
  const nonCustomerQueriesString = Object.entries(nonCustomerQueries)
    .map((a) => `${a[0]}=${a[1]}`)
    .join('&');
  // eslint-disable-next-line no-restricted-globals
  history.replaceState(null, '', `?${nonCustomerQueriesString}`);
}

new Vue({
  router,
  store,
  beforeCreate() {
    this.$store.dispatch('GET_IDENTIFY');
    this.$store.dispatch('INITIALISE_STORE');
    this.$store.dispatch('HAS_AUTH');
  },
  render: (h) => h(App),
}).$mount('#app');
