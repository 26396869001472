<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
    <path
      d="M7.5 2L9.4103 5.87069L13.6819 6.49139L10.5909 9.50431L11.3206 13.7586L7.5 11.75L3.6794 13.7586L4.40907 9.50431L1.31813 6.49139L5.5897 5.87069L7.5 2Z"
      fill="#FDCA16"
    />
  </svg>
</template>

<script>
export default {
  name: "Star",
};
</script>
