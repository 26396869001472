<template>
  <main>
    <section class="my-5 showcase-offers-container">
      <div class="container">
        <h2>Эти МФО одобрили Вашу анкету</h2>
        <p class="mb-0 text-secondary">
          Выберите нужную сумму и срок, <br />
          укажите данные получателя денежных средств и способ получения.
        </p>
        <offer-card
          type="offers"
          class="showcase-offers"
          showcase-id="41b6bb47-fc83-40fe-972e-63cb0734d4aa"
          :remove-after-click="false"
        >
          <template #card="{ offer, toRedirect, i }">
            <a
              :href="offer.pivot.link"
              @click.prevent="toRedirect(offer.pivot.link, offer.uuid)"
              class="card d-flex flex-column card-body shadow h-100 text-body showcase-offers-card"
            >
              <div class="showcase-offers-header d-flex flex-column">
                <span class="m-0 percent" v-if="offer.max_amount">
                  {{ offer.percent }}
                </span>
                <span class="d-flex gap-5 align-items-baseline m-0 title" v-if="offer.max_amount">
                  до <b>{{ formatAmount(offer.max_amount) }}</b> &#x20bd;
                </span>
              </div>
              <div class="showcase-offers-block d-flex align-items-center flex-column">
                <div
                  class="showcase-offers-rating
                  d-flex w-100 align-items-center justify-content-between"
                >
                  <div class="d-flex">Рейтинг:</div>
                  <div class="d-flex align-items-center justify-content-center gap-5">
                    <Star class="star-icon" />
                    <b>
                      {{ `${calculateStar(i)}/5` }}
                    </b>
                  </div>
                </div>
                <div class="showcase-offers-img w-100">
                  <img :src="offer.logo_url" alt="Offers Image" />
                </div>
                <div class="showcase-offers-inf w-100 d-flex flex-column gap-5">
                  <div class="d-flex gap-5 align-items-center">
                    <OfferBalance />
                    <!-- eslint-disable-next-line max-len -->
                    {{ `${formatAmount(offer.min_amount)} - ${formatAmount(offer.max_amount)}` }} &#x20bd;
                  </div>
                  <div class="d-flex gap-5 align-items-center">
                    <OfferCalendar />
                    {{ offer.max_period }}
                  </div>
                </div>
                <button type="button" class="showcase-offers-btn btn btn-block py-2 px-1 fw-sb">
                  <!-- {{ isCheating ? "Получить деньги" : "Заполнить заявку" }} -->
                  Получить деньги
                </button>
              </div>
            </a>
          </template>
        </offer-card>
      </div>
    </section>
  </main>
</template>

<script>
import { OfferCard } from 'timeleads-frontend-components';
import Star from '../components/svg/Star.vue';
import OfferBalance from '../components/svg/OfferBalance.vue';
import OfferCalendar from '../components/svg/OfferCalendar.vue';

export default {
  name: 'App',
  components: {
    OfferCard,
    Star,
    OfferBalance,
    OfferCalendar,
  },
  data() {
    return {
      env: process.env,
    };
  },
  methods: {
    calculateStar(i) {
      return i < 24 ? 5 - (5 - Math.ceil(5 - i / 4)) / 10 : 4.5;
    },
    formatAmount(amount) {
      return String(amount).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    },
  },
  computed: {
    isCheating() {
      return this.$abridged || this.$isTrustedWeb;
    },
  },
};
</script>
